import React from 'react';
import '@google/model-viewer';

function ArTest() {
    return (
        <div>
            <model-viewer
                src="https://modelviewer.dev/shared-assets/models/Astronaut.glb"
                alt="An astronaut"
                auto-rotate
                camera-controls
                ar
                ar-modes="scene-viewer webxr quick-look"
                style={{ width: '100%', height: '500px' }}
            ></model-viewer>
        </div>
    );
}

export default ArTest;