import { useEffect, useState, useRef } from "react";
import "./Ar-projection.css";
import { useParams } from "react-router-dom";
import '@google/model-viewer';

const BASE_URL = process.env.REACT_APP_API_URL;

function ArProjection() {
    const { restaurantName, mealId } = useParams();
    const [meals, setMeals] = useState([]);
    const [selectedMeal, setSelectedMeal] = useState(null);
    const carouselRef = useRef(null);

    // Function to fetch the meal details by ID
    const fetchMealById = async (id) => {
        try {
            const response = await fetch(`${BASE_URL}/meal/${id}`);
            const data = await response.json();
            if (data.code === 200) {
                setSelectedMeal(data.data.meal);
            } else {
                console.error("Error: Failed to load meal", data.message);
            }
        } catch (error) {
            console.error("Error fetching meal by ID:", error);
        }
    };

    useEffect(() => {
        const getListOfMeals = async () => {
            try {
                const response = await fetch(`${BASE_URL}/restaurant/find/${restaurantName}`);
                const filteredData = await response.json();
                const mealsList = filteredData.data?.restaurant?.meals || [];
                setMeals(mealsList);

                // Set the selected meal based on mealId from the URL
                if (mealId) {
                    await fetchMealById(mealId);
                } else if (mealsList.length > 0) {
                    setSelectedMeal(mealsList[0]);
                }

            } catch (error) {
                console.error("Error fetching meals:", error);
            }
        };

        getListOfMeals();
    }, [restaurantName, mealId]);

    const scrollLeft = () => {
        if (carouselRef.current) {
            const itemWidth = carouselRef.current.firstChild.offsetWidth + 10; // Include gap
            carouselRef.current.scrollBy({
                left: -itemWidth,
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            const itemWidth = carouselRef.current.firstChild.offsetWidth + 10; // Include gap
            carouselRef.current.scrollBy({
                left: itemWidth,
                behavior: "smooth",
            });
        }
    };

    const formatBase64Image = (imageFile) => {
        if (!imageFile) return null;
        return `data:image/jpeg;base64,${imageFile}`;
    };

    return (
        <div className="ar-projection">
            <div className="selected-meal">
                <h1>{selectedMeal?.name}</h1>

                {selectedMeal?.glbFile && (
                    <model-viewer
                        src={`${BASE_URL}/meal/file/${selectedMeal?.id}`}
                        alt={selectedMeal?.name}
                        ar
                        ar-modes="scene-viewer webxr quick-look"
                        environment-image="neutral"
                        auto-rotate
                        camera-controls
                        shadow-intensity="1"
                        exposure="1"
                        style={{width: "100%", height: "500px"}}
                    ></model-viewer>

                )}
            </div>

            <div className="carousel-container">
                {meals.length > 1 && (
                    <button onClick={scrollLeft} className="carousel-button left">
                        &#8592;
                    </button>
                )}

                <div className="carousel" ref={carouselRef}>
                    {meals.map((meal) => (
                        <img
                            key={meal.id}
                            onClick={() => fetchMealById(meal.id)}
                            className={`carousel-item ${meal.id === selectedMeal?.id ? "selected" : ""}`}
                            src={formatBase64Image(meal.imageFile) || "https://via.placeholder.com/150"}
                            alt={meal.name}
                        />
                    ))}
                </div>

                {meals.length > 1 && (
                    <button onClick={scrollRight} className="carousel-button right">
                        &#8594;
                    </button>
                )}
            </div>
        </div>
    );
}

export default ArProjection;