 import './App.css';


import {createBrowserRouter,RouterProvider} from "react-router-dom"
import Error404 from "./components/errorElement/Error404";
 import ArProjection from "./components/Ar-projection";
 import ArTest from "./components/Ar-test";

const router = createBrowserRouter([{

    path:'/',
    element:<h1>WELCOME</h1>,
    // eslint-disable-next-line react/style-prop-object
    errorElement:<Error404  />
},{
    path:'/:restaurantName/:mealId',
    element:<ArProjection/>,
    // eslint-disable-next-line react/style-prop-object
    errorElement:<Error404  />
},{
    path:"/test",
    element:<ArTest></ArTest>
}
])

function App() {
  return (
    <div className="App">
        <RouterProvider router={router}/>
    </div>
  );
}

export default App;
